import React from "react";

import { Col, Row, Table, Container } from "reactstrap";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

const ExpandedTable = ({ data }) => {
  return (
    <Container className="py-5">
      <>
        <Row>
          <Col>
            <div>Transaction ID: </div>
            <b>{data.transactionId}</b>

            <div className="mt-2">Username:</div>
            <b>{data.username}</b>

            <div className="mt-2">Upper:</div>
            <b>{data.upper}</b>
          </Col>
          <Col>
            <div className="mt-2">Date and Time:</div>
            <b>{data.date}</b>

            <div className="mt-2">Credit Type:</div>
            <img
              src={data.creditType === "CHIPS" ? chipsImage : coinsImage}
              alt=""
              width={20}
            />
            <b className="ml-2">{data.creditType}</b>

            <div className="mt-2">Remarks:</div>
            <b>{data.remarks}</b>
          </Col>
          <Col>
            <div className="mt-2">Amount:</div>
            <h1>{data.amount}</h1>
          </Col>
        </Row>
        <div className="mt-3">
          {data.mop && (
            <>
              <h2>Mode Of Payment</h2>
              <Table striped>
                <thead>
                  <tr>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Wallet Type</th>
                    <th>Provider</th>
                    <th>Minimum</th>
                    <th>Maximum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.mop.accountName}</td>
                    <td>{data.mop.accountNumber}</td>
                    <td>{data.mop.type}</td>
                    <td>
                      {data.mop.type === "BANK"
                        ? data.mop.bankType
                        : data.mop.electronicWalletType}
                    </td>
                    <td>{data.mop.minimum}</td>
                    <td>{data.mop.maximum}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </div>
      </>
    </Container>
  );
};

export default ExpandedTable;
