import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import Filter from "components/Filters";
import LoadingBar from "components/LoadBars";
import WithdrawalPINModal from "./p2p/WithdrawalPINModal";

import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";

import chipsLogo from "assets/img/chips-icon.png";
import coinsLogo from "assets/img/coins-icon.png";

import { useSelector } from "react-redux";
import { commas } from "views/shared/utils";

import { getLast20Transactions, getTransactions } from "api/Transactions";
import { columns } from "./data";

import { syncUserData } from "api/AccountAPI";
import { cash, coinBackground, key, withdraw } from "assets/img/homepage";
import { useDispatch } from "react-redux";
import { cn } from "lib/utils";

const MyAccount = () => {
  const balanceChips = useSelector((state) =>
    commas(state.entities.user.chips)
  );
  const balanceCoins = useSelector((state) =>
    commas(state.entities.user.coins)
  );

  const username = useSelector((state) => state.entities.user.username);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sync, setSync] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();

  const handleSync = () => {
    setSync(!sync);
    setLoading(true);
  };
  const [filter, setFilter] = useState();

  useEffect(() => {
    if (filter) {
      if (!filter.creditType) {
        filter.creditType = "CHIPS";
      }

      getTransactions(filter, filter?.creditType).then((response) => {
        setData(response);
        setLoading(false);
      });
    } else {
      getLast20Transactions().then((response) => {
        setData(response);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [sync]);

  const toggleSync = () => {
    setSync(!sync);
    setLoading(true);
  };

  const handleRefreshBalance = async () => {
    setRefreshing(true);
    await syncUserData(dispatch);
    setRefreshing(false);
  };

  return (
    <section
      style={{
        background: "url(" + coinBackground + ")",
        backgroundSize: "cover",
      }}
      className="py-5"
    >
      <div className="container">
        <div className="mb-5 flex flex-wrap items-center justify-between gap-5">
          <div className="flex items-center gap-5">
            <img
              alt="avatar-logo"
              className="circle-image border border-primary"
              src={
                process.env.REACT_APP_API_KEY +
                "/images/avatar/" +
                username +
                ".jpg"
              }
            />
            <div>
              <h3 className="mb-0 text-secondary">
                WELCOME, <span className="text-2xl">{username}</span>
              </h3>
              <div className="text-white">Hey player 👋 How are you?</div>
            </div>
          </div>

          <div className="flex flex-wrap gap-3">
            <div className="account-balance py-3 px-3 bg-dark rounded-md mt-2 min-w-60 ">
              <div className="flex justify-between items-center mb-2">
                <div className="title">Chips Balance</div>
                <div
                  onClick={handleRefreshBalance}
                  className={cn(
                    "cursor-pointer btn-inner--icon text-white",
                    refreshing && "animate-spin"
                  )}
                >
                  <i className="fa fa-refresh text-sm"></i>
                </div>
              </div>
              <h1 className="content mb-0 flex items-center gap-2 text-xl">
                <img alt="..." className="w-8 h-8" src={chipsLogo} />
                <span>{balanceChips}</span>
              </h1>
            </div>
            <div className="account-balance py-3 px-3 bg-dark rounded-md mt-2 min-w-60">
              <div className="title">Coins Balance</div>
              <h1 className="content mb-0 flex items-center gap-2 text-xl">
                <img alt="..." className="w-8 h-8" src={coinsLogo} />
                <span>{balanceCoins}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="d-flex mt-2">
              <Link to="/player/cashier/deposit">
                <div className="text-center bg-dark rounded-md py-2 px-4 mr-2 h-full">
                  <img alt="..." src={cash} height={60} />
                  <div className="h3 text-secondary">DEPOSIT</div>
                </div>
              </Link>
              <Link to="/player/cashier/withdraw">
                <div className="text-center bg-dark rounded-md py-2 px-3 h-full">
                  <img alt="..." src={withdraw} height={60} />
                  <div className="h3 text-secondary">WITHDRAW</div>
                </div>
              </Link>
              <div>
                <WithdrawalPINModal
                  content={
                    <div className="pointer ml-2">
                      <div className="text-center bg-dark rounded-md py-2 px-3">
                        <div>
                          <img alt="..." src={key} height={60} />
                        </div>

                        <span className="h3 text-secondary">PIN</span>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={6} className="mt-2 px-2"></Col>
        </Row>
        <Card className="bg-dark rounded-md mt-3">
          <CardHeader className="bg-transparent text-white">
            <Row>
              <Col lg={6} sm={12}>
                <h2 className="mb-0 text-white">Credit CI / CO Transactions</h2>
              </Col>
              <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                <Button
                  className="btn-icon btn-2"
                  color="default"
                  type="button"
                  outline
                  onClick={handleSync}
                >
                  <span className="btn-inner--icon text-white">
                    <i className="fa fa-refresh"></i>
                  </span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <div className="bg-white px-3">
            <Filter
              filter={setFilter}
              sync={toggleSync}
              searchToggle={loading}
              username={username}
              noUsername
              creditEnabled
            />
            <DataTableExtensions
              export={false}
              print={false}
              columns={columns}
              data={data}
            >
              <DataTable
                columns={columns}
                data={data}
                pagination
                striped
                responsive={true}
                progressComponent={<LoadingBar />}
                progressPending={loading}
              />
            </DataTableExtensions>
          </div>
        </Card>
      </Container>
    </section>
  );
};

export default MyAccount;
