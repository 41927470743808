import { useEffect, useRef } from "react";

// services
import SockJsClient from "react-stomp";
import { useDispatch, useSelector } from "react-redux";
import { checkSession } from "api/AccountAPI";
import { loggedOut } from "actions";

// utils
import { alertWithConfirm } from "views/homepage/utils";

const SOCKET_URL = process.env.REACT_APP_API_KEY + "/ws";

const AccountDetection = () => {
  const stompClient = useRef(null);
  const dispatch = useDispatch();

  const isLoggedIn =
    useSelector((state) => state.ui.loginOutcome) === "LOGGED_IN";
  const getUsername = useSelector((state) => state.entities.user.username);

  const messageLogout = (response) => {
    if (response?.statusCode !== "OK") {
      alertWithConfirm(
        "Connection lost, Another device is connecting to this account.",
        "Re-login",
        "warning",
        () => {
          window.location.reload();
        }
      );
      dispatch(loggedOut());
    }
  };

  let onMessageReceived = (response) => {
    messageLogout(response);
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkSession(getUsername).then((response) => {
        messageLogout(response);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoggedIn && (
        <div className="hidden">
          <SockJsClient
            url={SOCKET_URL}
            topics={[`/user/${getUsername}/session`]}
            onConnect={() => {}}
            onDisconnect={console.log("Disconnected!")}
            onMessage={(msg) => onMessageReceived(msg)}
            debug={true}
            ref={stompClient}
          />
        </div>
      )}
    </div>
  );
};

export default AccountDetection;
