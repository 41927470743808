import { commas } from "views/shared/utils";

import Confirm from "../modals/Confirm";
import Message from "../modals/Message";
import ProofPayment from "../modals/ProofPayment";

import { Table, Row, Col } from "reactstrap";

export const columnsUpper = (handleSync, isWithdrawAllowed, type) => [
  {
    name: "action",
    selector: (row) => row.transactionId,
    sortable: true,
    width: "12%",
    cell: (row) => (
      <Row>
        <Col>
          {isWithdrawAllowed && (
            <Confirm
              id={row.transactionId}
              user={row.username}
              amount={row.amount}
              isP2PTransaction={row.mop != null}
              type={type}
              sync={handleSync}
              hasProfOfPayment={row.proofOfPaymentCount !== 0}
            />
          )}
        </Col>
        <Col className="mt-2">
          {type === "DEPOSIT" && (
            <Message
              id={row.transactionId}
              user={row.username}
              amount={row.amount}
              messageCount={row.messageCount}
              isP2PTransaction={row.mop != null}
              sync={handleSync}
            />
          )}
        </Col>
      </Row>
    ),
  },
  {
    name: "transaction Id",
    width: "12%",
    sortable: true,
    selector: (row) => row.transactionId,
  },
  {
    name: "date and time",
    selector: (row) => {
      let dateAndTime = row.date.split(" ");
      return (
        <>
          <div>{dateAndTime[0]}</div>
          <div>{dateAndTime[1]}</div>
        </>
      );
    },
    sortable: true,
  },
  {
    name: "username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => (
      <div>
        <h4 className="text-nowrap">
          <b>{row.username}</b>
        </h4>
        <span>
          Upline: <b>{row.upper}</b>
        </span>
      </div>
    ),
  },
  {
    name: "Wallet",
    width: "15%",
    cell: (row) =>
      row.mop && (
        <>
          <Table bordered>
            <tbody>
              <tr>
                <th className="p-2" scope="row">
                  Wallet
                </th>
                <td className="p-2">
                  <b>
                    {row.mop.type === "BANK"
                      ? row.mop.bankType
                      : row.mop.electronicWalletType}
                  </b>
                </td>
              </tr>
              <tr>
                <th className="p-2" scope="row">
                  Name
                </th>
                <td className="p-2">{row.mop.accountName}</td>
              </tr>
              <tr>
                <th className="p-2" scope="row">
                  #
                </th>
                <td className="p-2">
                  <b>{row.mop.accountNumber}</b>
                </td>
              </tr>
            </tbody>
          </Table>
          {/* <div>
            Wallet{" "}
            <b>
              {row.mop.type === "BANK"
                ? row.mop.bankType
                : row.mop.electronicWallet}
            </b>
            <br />
            No. <b>{row.mop.accountNumber}</b> <br />
            Name <b>{row.mop.accountName}</b>
          </div> */}
        </>
      ),
  },
  {
    name: "amount",
    selector: (row) => <b className="text-lg">{commas(row.amount)}</b>,
    sortable: true,
  },
  // {
  //   name: "credit Type",
  //   selector: (row) => row.creditType,
  //   sortable: true,
  //   cell: (d) => (
  //     <div className="d-flex flex-column justify-content-center">
  //       <div>
  //         <img
  //           src={d.creditType === "CHIPS" ? chipsImage : coinsImage}
  //           alt=""
  //           width={40}
  //         />
  //       </div>
  //       <div>
  //         <b className="ml-1">{d.creditType}</b>
  //       </div>
  //     </div>
  //   ),
  // },
  {
    name: "Screenshots",
    sortable: true,
    cell: (row) => <ProofPayment item={row} />,
  },
  {
    name: "Remarks",
    selector: (row) => row.remarks,
    sortable: true,
  },
];
